import * as React from "react"
import { graphql } from 'gatsby'
import Header from "../../templates/Header/index";
import HeroSection from "./sections/hero-section";
import SecondSection from './sections/second-section';
import ThirdSection from './sections/third-section';
import FourthSection from './sections/fourth-section';
import FifthSection from './sections/fifth-section';
import MinimalFooter from '../../templates/MinimalFooter/index';
import Helmet from 'react-helmet';

const HeaderProperties = () => {
  return (
      <Helmet>
          <title>Collexin: Increase collections, decrease frustrations.</title>
          <meta
              name="description"
              content="Collexin is a digital platform that eliminates the headaches and costly process of traditional debt collection. Using technology, Collexin delivers a streamlined, personalized experience, that drives customers to take control, and collectors to drive up returns."
          />
          <meta
              property="og:url"
              content="https://www.collexin.com/"
          />
          <meta
              property="og:title"
              content="Collexin: Increase collections, decrease frustrations."
          />
          <meta
              property="og:description"
              content="Collexin is a digital platform that eliminates the headaches and costly process of traditional debt collection. Using technology, Collexin delivers a streamlined, personalized experience, that drives customers to take control, and collectors to drive up returns."
          />
      </Helmet>
  )
};

const HomeTemplate = ({ data }) => {
  return (

    <React.Fragment>
      {data !== null ?
        <>
          <Header />
          <HeroSection
            titleOne={data.heroSectionTitleOne}
            titleTwo={data.heroSectionTitleTwo}
            paragraph={data.heroSectionDescription}
            heroImage={data.heroImage}
            heroImageAlt={data.heroImageAlt}
          />

          <SecondSection 
            title={data.secondSectionTitle}
            subTitle={data.secondSectionSubTitle}
            description={data.secondDescription}
            sectionPoints={data.secondSection}
            image={data.secondSectionImage}
            imageAlt={data.secondSectionImageAlt}
          />

          <ThirdSection 
            title={data.thirdSectionTitle}
            subTitle={data.thirdSectionSubTitle}
            description={data.thirdDescription}
            sectionPoints={data.thirdSection}
            image={data.thirdSectionImage}
            imageAlt={data.thirdSectionImageAlt}
          />

          <FourthSection 
            title={data.fourthSectionTitle}
            subTitle={data.fourthSectionSubTitle}
            problems={data.problemSection}
            solutions={data.solutionSection}
          />

          <FifthSection 
            title={data.fifthSectionTitle}
            description={data.fifthSectionDescription}
            image={data.fifthSectionImage}
            imageAlt={data.fifthSectionImageAlt}
          />
          <MinimalFooter />
        </>
        : null}
    </React.Fragment>
  );
};

const Home = ({ data }) => {
  const {
    markdownRemark: { frontmatter },
  } = data;

  return (
    /*  <Layout> */
    <>
    <HeaderProperties />
    <HomeTemplate data={frontmatter} />
    </>
    /* </Layout> */
  );
};


export default Home;

export const HomePageQuery = graphql`
  query HomePage($slug: String!) {
      markdownRemark(fields: { slug: { eq: $slug} }) {
        frontmatter {
          title
          heroSectionTitleOne
          heroSectionTitleTwo
          heroSectionDescription
          heroImage {
              childImageSharp {
                  gatsbyImageData (
                      placeholder: NONE
                      quality: 100
                  )
              }
          }
          heroImageAlt
          secondSectionTitle
          secondSectionSubTitle
          secondSectionImage {
            childImageSharp {
                gatsbyImageData (
                    placeholder: NONE
                    quality: 100
                )
            }
          }
          secondSectionImageAlt
          secondDescription
          secondSection {
            title
            description
            icon {
              childImageSharp {
                  gatsbyImageData (
                      placeholder: NONE
                      quality: 100
                      height: 65
                  )
              }
            }
            iconAlt
          }
          thirdSectionTitle
          thirdSectionSubTitle
          thirdSectionImage {
            childImageSharp {
                gatsbyImageData (
                    placeholder: NONE
                    quality: 100
                )
            }
          }
          thirdSectionImageAlt
          thirdDescription
          thirdSection {
            title
            description
            icon {
              childImageSharp {
                  gatsbyImageData (
                      placeholder: NONE
                      quality: 100
                      height: 47
                  )
              }
            }
            iconAlt
          }
          fourthSectionTitle
          fourthSectionSubTitle
          problemSection {
            title
            description
          }
          solutionSection {
            title
            description
          }
          fifthSectionTitle
          fifthSectionDescription
          fifthSectionImage {
            childImageSharp {
                gatsbyImageData (
                    placeholder: NONE
                    quality: 100
                )
            }
          }
          fifthSectionImageAlt
          sectionTitle
          sectionDescription
          alternatingSection {
            title
            imageAlign
            image {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                    )
                }
            }
            icons {
              icon {
                childImageSharp {
                  gatsbyImageData(
                      placeholder: NONE
                  )
              }
            }
            iconHeading
            iconText
          }
        }
        backgroundSectionTitle
        backgroundSectionDescription
        backgroundSubTitle
        backgroundSection {
          icons {
            icon {
              childImageSharp {
                gatsbyImageData(
                    placeholder: NONE
                )
            }
          }
          iconHeading
          iconText
        }
        }
        }           
      }   
  }
`;

