import React from 'react';
import styled from 'styled-components';
import BackgroundImage from '../../../../resources/images/home/business/Circle-Graphic-for-Infographic.png'

const Background = styled.div`
    width: 100%;
    background: #9DB7C4;
    background-image: url(${BackgroundImage});
    background-size: cover;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;

    @media (max-width: 600px) {
        background-size: 100% 80%;
    }
`;

const Wrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 200px;
    
    @media (max-width: 1250px) {
        max-width: none;
        width: 100%;
    }
    @media (max-width: 769px) {
        padding: 0px 0 20px 0;
    }

    @media (max-width: 500px) {
        padding-top: 0;
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 80px 0 50px 0;
    font-weight: 900;
    width: 100%;
    text-align: center;
    margin: 0 auto;

    @media (max-width: 1250px) {
        padding: 150px 20px 0 25px;
        flex-basis: 100%;
    }
    
    @media (max-width: 769px) {
        padding: 100px 20px 0 20px;
        

    }

    @media (max-width: 500px) {
        padding: 110px 15px 0 15px;
        width: 100%;
    }
`;

const Title = styled.h1`
    font-size: 45px;
    color: #00536D;
    font-weight: 900;
    line-height: 1.2;
    width: 90%;
    margin: 0 auto;

    @media (max-width: 1200px) {
        width: 100%;
    }

    @media (max-width: 769px) {
        text-align: center;
    }
    @media (max-width: 550px) {
        font-size: 29px;
        line-height: 1.3;
    }
`;

const SubTitle = styled.h3`
    font-size: 18px;    
    font-weight: 600;
    color: #3C7A90;
    line-height: 1.5;
    width: 70%;
    margin: 20px auto;

    @media (max-width: 1200px) {
        width: 80%;
    }

    @media (max-width: 769px) {
        text-align: center;
        width: 100%;
    }
`;


const ProblemHeader = styled.h3`
    font-size: 50px;    
    font-weight: 900;
    color: #52889A;
    line-height: 1.5;
    text-align: right;
    margin: 0 220px 20px 0;

    @media (max-width: 1200px) {
        width: 80%;
        margin: 0 120px 20px 0;
    }

    @media (max-width: 769px) {
        text-align: center;
        width: 100%;
        margin: 0 0px 0px 0;
        font-size: 30px;
    }
    @media (max-width: 600px) {
        text-align: left;
        margin-bottom: 0;
        line-height: 1.8;
    }
`;

const SolutionHeader = styled.h3`
    font-size: 50px;    
    font-weight: 900;
    color: #3D7180;
    line-height: 1.5;
    margin-bottom: 20px;
    text-align: right;
    margin-top: 0;

    @media (max-width: 1200px) {
        text-align: left;
    }

    @media (max-width: 769px) {
        width: 100%;
        font-size: 30px;
        margin-bottom: 0;
    }
    @media (max-width: 550px) {
        margin-bottom: 0;
        line-height: 1.8;
    }
`;

const ProblemSolutionContainer = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 70px;

    @media (max-width: 600px) {
        flex-direction: column;
    }
`;

const NumberContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: start;
    height: 200px;
    width: 100%;

    @media (max-width: 1200px) {
        height: 250px;
    }
    @media (max-width: 769px) {
        height: 350px;
    }
    @media (max-width: 600px) {
        height: 100%;
    }
`;

const Number = styled.h5`
    font-size: 50px;
    color: white;
    font-weight: 900;
    margin: 0 90px;

    @media (max-width: 1200px) {
        margin: 0 50px;
    }

    @media (max-width: 769px) {
        margin: 0 30px;
    }

    @media (max-width: 600px) {
        display: none;
    }
`;

const ProblemContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    width: 60%;
    align-items: end;

    @media (max-width: 1200px) {
        width: 55%;
    }

    @media (max-width: 769px) {
        padding-left: 20px;
    }
    @media (max-width: 600px) {
        width: 100%;
        padding: 0 20px;
    }
`;


const Problem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: end;
    text-align: right;

    @media (max-width: 600px) {
        align-items: start;
        text-align: left;
        margin-bottom: 30px;
    }
`;

const ProblemTitle = styled.h4`
    color: #52889A;
    font-size: 22px;
    margin: 10px 0;
    text-align: right;

    @media (max-width: 769px) {
        font-size: 20px;
    }
    
`;

const ProblemDescription = styled.p`
    color: #52889A;
    font-size: 18px;
    @media (max-width: 600px) {
        text-align: left;
    }
`;

const SolutionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 45%;
    align-items: start;

    @media (max-width: 1200px) {
        width:  40%;   
    }
    @media (max-width: 769px) {
        padding-right: 20px;
    }
    @media (max-width: 600px) {
        width: 100%;
        padding: 0 20px;
    }
`;

const Solution = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    height: 200px;

    @media (max-width: 1200px) {
        height: 250px;
    }
    @media (max-width: 769px) {
        height: 350px;
    }
    @media (max-width: 600px) {
        height: 100%;
        margin-bottom: 30px;
    }
`;


const SolutionTitle = styled.h4`
    color: #3D7180;
    font-size: 22px;
    margin: 10px 0;
    text-align: left;

    @media (max-width: 769px) {
        font-size: 20px;
    }
`;

const SolutionDescription = styled.p`
    color: #3D7180;
    font-size: 18px;
`;

const ThirdSection = ({ 
    title,
    subTitle,
    problems,
    solutions
}) => {
    return (  
        <Background>
        <Wrapper>
            <TextContainer>
                <Title>{ title }</Title>
                <SubTitle>{ subTitle }</SubTitle> 
            </TextContainer>

            <ProblemSolutionContainer>     
                <ProblemContainer>
                    <ProblemHeader>The Problem</ProblemHeader>
                    {problems.map((problem, index) => {
                        return (
                            <>
                            <NumberContainer>
                                <Problem>
                                    <ProblemTitle>{problem.title}</ProblemTitle>
                                    <ProblemDescription>{problem.description}</ProblemDescription>
                                </Problem>
                                <Number>{index + 1}</Number>
                            </NumberContainer>

                            </>
                        )
                    })}
                </ProblemContainer>

                <SolutionContainer>
                    <SolutionHeader>The Solution</SolutionHeader>
                    {solutions.map((solution, index) => {
                        return (
                            <Solution>
                                <SolutionTitle>{solution.title}</SolutionTitle>
                                <SolutionDescription dangerouslySetInnerHTML={{ __html: solution.description }} />
                            </Solution>
                        )
                    })}
                </SolutionContainer>
            </ProblemSolutionContainer>    
        </Wrapper>
        </Background>
    );
};

export default ThirdSection;
