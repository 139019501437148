import React from 'react';
import styled from 'styled-components';
import DynamicImage from '../../../atoms/DynamicImage';
import Cta from '../../../atoms/CTA';

const Container = styled.div`
    padding-bottom: 50px;
    margin-top: -100px;
`;

const Wrapper = styled.div`
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    

    @media (max-width: 1250px) {
        max-width: none;
        width: 100%;
    }
    @media (max-width: 769px) {
        padding: 0px 0 20px 0;
    }

    @media (max-width: 500px) {
        padding-top: 0;
    }
`;

const FlexContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    align-items: center;

    @media (min-width: 920px) and (max-width: 1250px) {
        align-items: end;
    }
    @media (max-width: 1200px) {
        max-width: none;
        width: 100%;
        margin: 0 auto;
    }

    @media (max-width: 769px) {
        flex-direction: column;
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 0 160px 0;
    font-weight: 900;
    width: 80%;

    @media (max-width: 1250px) {
        padding: 150px 20px 0 25px;
        flex-basis: 100%;
    }
    
    @media (max-width: 769px) {
        padding: 0px 20px 0 20px;
    }

    @media (max-width: 500px) {
        padding: 0px 15px 0 15px;
        width: 100%;
    }
`;

const Title = styled.h1`
    font-size: 40px;
    color: #27A04B;
    margin: -10px 0 0 0;
    max-width: 600px;
    font-weight: 900;
    line-height: 1.2;

    @media (max-width: 1200px) {
        width: 100%;
    }

    @media (max-width: 769px) {
        text-align: center;
    }
    @media (max-width: 550px) {
        font-size: 30px;
        line-height: 1.3;
    }
`;

const SubTitle = styled.h3`
    font-size: 18px;    
    font-weight: 600;
    color: #3C7A90;
    line-height: 1.5;
    margin-bottom: 20px;
    max-width: 574px;

    @media (max-width: 1200px) {
        width: 80%;
    }

    @media (max-width: 769px) {
        text-align: center;
        width: 100%;
        margin-bottom: 0;
    }
    @media (max-width: 550px) {
        margin-bottom: 0;
        line-height: 1.8;
    }
`;


const HeroImageContainer = styled.div`
    width: 100%;
`; 

const CtaContainer = styled.div`
    width: 300px;
    margin-top: 20px;

    @media (max-width: 769px) {
        margin: 30px auto;
        text-align: center;
    }
`;


const FifthSection = ({ 
    title,
    description,
    image,
    imageAlt
}) => {
    return (   
        <Container>
            <Wrapper>
                <FlexContainer>
                    <TextContainer>
                        <Title>{ title }</Title>
                        <SubTitle>{ description }</SubTitle> 
                        <CtaContainer>
                            <Cta href="/contact-us">Learn More</Cta>
                        </CtaContainer>
                    </TextContainer>

                    <HeroImageContainer>
                        <DynamicImage
                            src={image}
                            alt={imageAlt}
                        />
                    </HeroImageContainer>
                
                </FlexContainer>
            </Wrapper>
        </Container>
    );
};

export default FifthSection;
