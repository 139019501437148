import React from 'react';
import styled from 'styled-components';
import DynamicImage from '../../../atoms/DynamicImage';

const Container = styled.div`
    padding-bottom: 50px;
    background: #E8EDED;
    position: relative: 
    z-index: -2;
`;

const Wrapper = styled.div`
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    

    @media (max-width: 1250px) {
        max-width: none;
        width: 100%;
    }
    @media (max-width: 769px) {
        padding: 0px 0 20px 0;
    }

    @media (max-width: 500px) {
        padding-top: 0;
    }
`;

const FlexContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    align-items: center;

    @media (min-width: 920px) and (max-width: 1250px) {
        align-items: end;
    }
    @media (max-width: 1200px) {
        max-width: none;
        width: 100%;
        margin: 0 auto;
    }

    @media (max-width: 769px) {
        flex-direction: column;
    }
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 100px 0 80px 0;
    font-weight: 900;
    width: 100%;

    .greenText {
        color: #27A04B;
    }

    .blueText {
        color: #10526E;
    }
    @media (max-width: 1250px) {
        padding: 150px 20px 0 25px;
        width: 55%;
    }
    
    @media (max-width: 769px) {
        padding: 100px 20px 0 20px;
        width: 100%;
        align-items: center;
    }

    @media (max-width: 500px) {
        padding: 50px 15px 0 15px;
        width: 100%;
    }
`;

const Title = styled.h1`
    font-size: 45px;
    color: #27A04B;
    margin: -10px 0 0 0;
    max-width: 710px;
    font-weight: 900;
    line-height: 1.2;

    @media (max-width: 1200px) {
        width: 100%;
    }

    @media (max-width: 769px) {
        text-align: center;
    }
    @media (max-width: 500px) {
        font-size: 35px;
    }
`;

const SubTitle = styled.h3`
    font-size: 18px;    
    font-weight: 600;
    color: #5F99AC;
    line-height: 1.5;
    margin-bottom: 20px;
    max-width: 574px;

    @media (max-width: 1200px) {
        width: 80%;
    }

    @media (max-width: 769px) {
        text-align: center;
        width: 100%;
        margin-bottom: 0;
    }
`;


const Description = styled.h3`
    font-size: 30px;    
    font-weight: 900;
    color: #27A04B;
    line-height: 1.5;
    margin-bottom: 45px;
    padding-left: 8px;
    z-index: 3;

    @media (max-width: 1200px) {
        padding: 0 25px;
    }

    @media (max-width: 769px) {
        text-align: left;
        width: 100%;
        margin-bottom: 0;
        margin-top: 420px;
    }
    @media (max-width: 690px) {
        margin-top: 300px;
    }
    @media (max-width: 500px) {
        font-size: 22px;
    }

`;

const HeroImageContainer = styled.div`
    z-index: 1;
    position: absolute;
    width: 750px;
    right: -100px;
    top: 50px;

    @media (max-width: 769px) {
        top: 320px;
        right: 0;
        left: 0;
        margin: auto;
        opacity: .7;
        width: 100%;
    }
    @media (max-width: 500px) {
        top: 390px;
    }
`; 

const PointContainer = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .half-width {
        width: 47.3%;
    }

    @media (max-width: 860px) {
        padding-left: 15px;

        .half-width {
            width: 45%;
        }
    }
    @media (max-width: 769px) {
        padding: 0 15px;
        .half-width {
            width: 46%;
        }
    }
    @media (max-width: 530px) {
        .half-width {
            width: 93%;
        }
    }
`;


const Point = styled.div`
    width: 31%;
    display: flex;
    flex-direction: column;
    margin: 10px;
    background: #F8F8F9;
    box-shadow: 0px 3px 6px #00000029;
    padding: 30px 30px 60px 30px;
    border-radius: 10px;
    height: 360px;
    z-index: 2;

    @media (max-width: 860px) {
        width: 30%;
    }

    @media (max-width: 769px) {
        width: 30%;
        height: 450px;
        padding: 30px 20px 50px 20px;
    }
    @media (max-width: 630px) {
        width: 46%;
        height: 400px;
    }
    @media (max-width: 530px) {
        width: 100%;
        height: 320px;
    }
`;

const PointTitle = styled.h4`
    color: #5B97AA;
    font-size: 22px;
    margin: 10px 0 10px 0;
`;

const PointDescription = styled.p`
    color: #7CA683; 
    font-size: 18px;
`;
// 7AA3B7
const IconContainer = styled.div`
    margin-bottom: 30px;

    @media (max-width: 769px) {
        height: 45px;
        width: 45px;
        margin-bottom: 15px;
    }
`;

const SecondSection = ({ 
    title,
    subTitle,
    description,
    sectionPoints,
    image,
    imageAlt
}) => {
    return (   
        <Container>
            <Wrapper>
                <FlexContainer>
                    <TextContainer>
                        <Title>{ title }</Title>
                        <SubTitle>{ subTitle }</SubTitle> 
                    </TextContainer>

                    <HeroImageContainer>
                        <DynamicImage
                            src={image}
                            alt={imageAlt}
                        />
                    </HeroImageContainer>
                                    
                </FlexContainer>

                <Description>{ description }</Description>
                <PointContainer>
                    {sectionPoints.map((point, index) => {
                        return (
                            <>
                            {index < 3 ? 
                            <Point>
                                <IconContainer>
                                    <DynamicImage
                                        src={point.icon}
                                        alt={point.iconAlt}
                                    />
                                </IconContainer>
                                <PointTitle>{point.title}</PointTitle>
                                <PointDescription>{point.description}</PointDescription>
                            </Point>
                            : 
                            <Point className="half-width">
                                <IconContainer>
                                    <DynamicImage
                                        src={point.icon}
                                        alt={point.iconAlt}
                                    />
                                </IconContainer>
                                <PointTitle>{point.title}</PointTitle>
                                <PointDescription>{point.description}</PointDescription>
                            </Point>
                            }
                            </>
                        )
                    })}

                </PointContainer>
            </Wrapper>
        </Container>
    );
};

export default SecondSection;
